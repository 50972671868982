<template>
  <div class="designer-setting-pannel">
    <div class="div-scroll">
      <div class="setting-pannel-title">绑定手机号</div>
      <div class="step-container">
        <el-steps :active="current" align-center>
          <el-step title="验证身份" />
          <el-step title="绑定手机" />
          <el-step title="绑定成功" />
        </el-steps>
      </div>

      <div class="setting-pannel-content">
        <div v-if="current === 0">
          <el-form
            class="phone-form"
            label-position="right"
            label-width="10px"
            :model="form"
            :rules="rules"
            ref="ruleForm1"
          >
            <el-form-item prop="phone">
              <el-input v-model="form.phone" disabled size="large" />
            </el-form-item>

            <el-form-item prop="authCode">
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-input size="large" v-model="form.authCode" />
                </el-col>
                <el-col :span="8">
                  <el-button
                    size="large"
                    :disabled="isSend"
                    @click="getAuthCode('form')"
                  >
                    获取验证码<span v-show="isSend && times != 0"
                      >({{ times }})s
                    </span>
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <div style="text-align: center; padding-top: 20px">
              <el-button
                key="back"
                @click="validateAuthCode('form', 1)"
                v-if="current === 0"
                :disabled="isLoading"
              >
                下一步
              </el-button>
            </div>
          </el-form>
        </div>
        <div v-if="current === 1">
          <el-form
            class="phone-form"
            label-position="right"
            label-width="10px"
            :model="phoneForm"
            :rules="rules"
            ref="ruleForm2"
          >
            <el-form-item prop="phone">
              <el-input
                placeholder="请输入手机号"
                v-model="phoneForm.phone"
                size="large"
              />
            </el-form-item>
            <el-form-item prop="authCode">
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-input size="large" v-model="phoneForm.authCode" />
                </el-col>
                <el-col :span="8">
                  <el-button
                    size="large"
                    :disabled="isSend"
                    @click="getAuthCode('phoneForm')"
                  >
                    获取验证码<span v-show="isSend && times != 0"
                      >({{ times }})s
                    </span>
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <div style="text-align: right; padding-top: 20px">
              <el-button
                key="back"
                type="primary"
                @click="validateAuthCode('phoneForm', 2)"
                v-if="current === 1"
                :disabled="isLoading"
              >
                下一步
              </el-button>
            </div>
          </el-form>
        </div>

        <el-result
          icon="success"
          title="手机号绑定成功,请重新登录!"
          v-if="current === 2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { isPhone } from "@/libs/checker.js";
export default {
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!isPhone(value)) {
          callback(new Error("请输入正确的手机号"));
        }
        callback();
      }
    };
    return {
      SUCCESS_CODE: 0,
      form: {
        phone: "",
        authCode: "",
      },
      phoneForm: {
        phone: "",
        authCode: "",
      },
      rules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
        authCode: [
          { required: true, trigger: "blur", message: "请输入验证码" },
        ],
      },
      isLoading: false,
      current: 0,
      times: "", // 计时
      isSend: false,
      timer: 0,
    };
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.form.phone = userInfo.username;
  },
  methods: {
    // 修改绑定手机号
    modifyTel() {
      let tel = this.phoneForm.phone;
      this.isLoading = true;
      this.$http
        .post("/sys-user/changeTel", null, { params: { tel } })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            setTimeout(() => {
              this.logout();
            }, 1000);
          } else {
            this.$message.error("修改失败！");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.$message.error("修改失败！");
          this.isLoading = false;
        });
    },
    // 获取验证码
    getAuthCode(type) {
      let self = this;
      if (self.isSend) {
        return;
      }
      // 点击按钮时先校验手机号
      if (!isPhone(self[type].phone)) {
        return this.$message.error(`请输入正确的手机号`);
      }
      // 判断手机号是否更改
      if (this.form.phone == this.phoneForm.phone) {
        return this.$message.warning(
          "新绑定手机号不可与原手机号一致，请修改！"
        );
      }
      let params = {
        tel: self[type].phone,
      };
      // 新手机才需校验是否注册过，type为1用于判断是绑定手机号时获取验证码，否则为注册时获取验证码
      if (type == "form") {
        params.type = 2;
      } else {
        params.type = 1;
      }
      self.isSend = true;
      this.$http
        .post("/sys-user/getCheckCode", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success("短信发送成功！");
            self.countTime(60);
          } else {
            self.buttonTxt = "获取验证码";
            self.isSend = false;
            self.times = 0;
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          self.buttonTxt = "获取验证码";
          self.isSend = false;
          self.times = 0;
          this.$message.error("获取失败，" + err);
        });
    },
    // 获取验证码，倒计时
    countTime(time) {
      let self = this;
      self.buttonTxt = "重新获取";
      self.times = Number(time);
      if (self.timer) {
        clearInterval(self.timer);
      }
      self.timer = setInterval(function () {
        if (self.times == 0) {
          clearInterval(self.timer);
          self.buttonTxt = "获取验证码";
          self.isSend = false;
        } else {
          self.times--;
        }
      }, 1000);
    },
    // 校验验证码
    validateAuthCode(type, index) {
      this.$refs["ruleForm" + index].validate((valid) => {
        if (valid) {
          this.isLoading = true;
          let params = {
            tel: this[type].phone,
            code: this[type].authCode,
          };
          this.$http
            .get("sys-user/validateCode", { params })
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.current++;
                clearInterval(this.timer);
                this.isSend = false;
                this.timer = null;
                this.times = 0;
                if (index == 2) {
                  // 新手机号验证通过后，调取绑定手机号接口
                  this.modifyTel();
                }
              } else {
                this.$message.error(res.msg);
              }
              this.isLoading = false;
            })
            .catch((err) => {
              this.isLoading = false;
              this.$message.error("校验失败" + err);
            });
        } else {
          return;
        }
      });
    },
    logout() {
      localStorage.clear();
      this.$router.replace({
        path: "/login",
      });
    },
  },
};
</script>
<style lang="less">
.designer-setting-pannel {
  .el-step__head.is-finish {
    .el-step__line {
      background-color: rgb(103, 63, 245);
    }
  }
  .el-step__icon {
    width: 30px;
    height: 30px;
    background-color: rgba(103, 63, 245, 1);
    position: relative;
    color: #fff;
    border: none;
    &.is-text {
      border: none;
    }
    .el-step__icon-inner {
      position: relative;
      z-index: 11;
    }
    &:after {
      content: "";
      width: 50px;
      height: 50px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      background: rgba(103, 63, 245, 0.2);
      transform: translate(-50%, -50%);
    }
  }
  .el-step__main {
    .el-step__title.is-finish {
      color: rgb(103, 63, 245);
    }
  }
  .el-button {
    border-color: #8565f6;
    background-color: #fff;
    color: #8565f6;
  }
  .el-row {
    position: relative;
    .el-col.el-col-8 {
      height: 20px;
      position: absolute;
      top: 10px;
      right: -18px;
      border-left: 1px solid #8565f6;
      display: block;
      line-height: 20px;
      .el-button {
        border: none;
        padding: 0 20px;
        background-color: transparent;
        color: #8565f6;
        &:hover {
          border: none;
          background-color: transparent;
        }
      }
    }
  }
  .el-step__main {
    margin-top: 10px;
  }
  .el-step__title {
    color: #999;
    font-size: 14px;
    &.is-process {
      color: #3d3d3d;
    }
  }
}
</style>